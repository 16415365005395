/* src/styles/Transactions.css */

/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');

/* General Styles */
body {
  font-family: 'Urbanist', sans-serif;
  color: #1A1A40;
}

/* Transactions Content Wrapper */
.transactions-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header Section */
.transactions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; /* Match spacing with Dashboard */
}

.transactions-header .transactions-title h2 {
  font-size: 20px; /* Match font size with Dashboard */
  margin: 0;
}

.transactions-header .date-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.transactions-header .date-selector input {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Action Bar */
.transactions-action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.transactions-tabs {
  display: flex;
  gap: 10px;
}

.tab-button {
  flex: 1; /* Make all tabs the same width */
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #F0F2F5;
  color: #1A1A40;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-button.active,
.tab-button:hover {
  background-color: #7B61FF; /* Original purple color */
  color: #fff;
}

/* Search and Download Actions */
.transactions-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-bar {
  position: relative;
}

.search-bar input {
  padding: 8px 12px 8px 35px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.download-button {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #7B61FF;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #6949FF;
}

.download-icon {
  font-size: 16px;
}

/* Transactions Table Container */
.transactions-table-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.transactions-table-wrapper {
  flex: 1;
  overflow-y: auto;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.transactions-table th,
.transactions-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
}

.transactions-table th {
  background-color: #F0F2F5;
  font-weight: 600;
}

.transactions-table tr:hover {
  background-color: #F9F9F9;
}

.no-transactions {
  text-align: center;
  padding: 50px 0;
}

.no-transactions-message p {
  margin: 5px 0;
  color: #666;
}

/* Status Labels */
.status {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.status.success {
  background-color: #2ECC71;
}

.status.pending {
  background-color: #F1C40F;
}

.status.failed {
  background-color: #E74C3C;
}

.action-button {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #7B61FF;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #6949FF;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: flex-start; /* Align to the bottom left */
  align-items: center;
  margin-top: 15px;
  gap: 5px;
}

.page-button {
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  background-color: transparent; /* No background color */
  color: #1A1A40;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.page-button.active {
  background-color: #B8A4FF; /* Light purple color for selected page */
  color: #fff;
}

.page-button:hover:not(.active) {
  background-color: #F0F2F5; /* Light gray on hover */
}

.page-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

/* Hover Effects */
.tab-button:hover,
.download-button:hover,
.action-button:hover,
.page-button:hover:not(.disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Transitions */
.tab-button,
.download-button,
.action-button,
.page-button {
  transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .transactions-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .transactions-header .date-selector {
    margin-top: 10px;
  }

  .transactions-action-bar {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .transactions-tabs {
    width: 100%;
    gap: 5px;
  }

  .tab-button {
    flex: 1;
    padding: 10px;
    font-size: 14px;
  }

  .transactions-actions {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar,
  .download-button {
    width: 100%;
  }

  .transactions-table th,
  .transactions-table td {
    padding: 10px;
    font-size: 12px;
  }

  .page-button {
    padding: 5px 8px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .transactions-header .transactions-title h2 {
    font-size: 16px;
  }

  .transactions-header .date-selector input {
    font-size: 12px;
    padding: 6px 8px;
  }

  .transactions-action-bar {
    gap: 10px;
  }

  .transactions-tabs {
    gap: 5px;
  }

  .tab-button {
    padding: 8px 10px;
    font-size: 12px;
  }

  .search-bar input {
    font-size: 12px;
    padding: 6px 8px 6px 30px;
  }

  .search-icon {
    left: 8px;
  }

  .download-button {
    font-size: 12px;
    padding: 6px 8px;
  }

  .download-icon {
    font-size: 14px;
  }

  .transactions-table th,
  .transactions-table td {
    padding: 8px;
    font-size: 11px;
  }

  .action-button {
    padding: 4px 8px;
    font-size: 11px;
  }

  .page-button {
    padding: 4px 6px;
    font-size: 11px;
  }
}


/* General styling */
body, input, button {
  font-family: 'Urbanist', sans-serif;
}

/* Modal styles */
.custom-modal {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  margin: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  color: #333333;
}

.custom-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal header styles */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
}

/* Close button styles */
.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #333333;
}

/* Modal content */
.modal-content p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555555;
}

/* Transaction tabs */
.transactions-tabs {
  display: flex;
  font-family: 'Urbanist', sans-serif;
}

.tab-button {
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  cursor: pointer;
  font-family: 'Urbanist', sans-serif;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #333333;
  color: white;
}

.transactions-actions {
  display: flex;
  align-items: center;
}

/* Search bar */
.search-bar input {
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Date filter */
.date-filter input {
  padding: 10px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Download button */
.download-button {
  padding: 10px 20px;
  background-color: #333333;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.download-icon {
  margin-right: 5px;
}

/* Transactions table */
.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th,
.transactions-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transactions-table th {
  background-color: #f5f5f5;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-button {
  padding: 10px;
  margin: 0 5px;
  border: none;
  cursor: pointer;
  background-color: #f5f5f5;
}

.page-button.active {
  background-color: #333333;
  color: white;
}








/* Status Styles... */
.status {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
}

.status.success {
  background-color: var(--color-success);
}

.status.pending {
  background-color: var(--color-pending);
}

.status.failed {
  background-color: var(--color-failed);
}

/* No Records */
.no-records {
  text-align: center;
  padding: 20px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 64, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 16px;
  width: 500px;
  max-width: 90%;
  position: relative;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--color-primary);
}

.modal-content p {
  margin-bottom: 10px;
  color: var(--color-primary);
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.refund-button,
.chargeback-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.refund-button {
  background-color: var(--color-secondary);
  color: #fff;
}

.refund-button:hover {
  background-color: #6b52e6;
}

.chargeback-button {
  background-color: #e74c3c;
  color: #fff;
}

.chargeback-button:hover {
  background-color: #c0392b;
}

.close-button {
  margin-top: 20px;
  padding: 8px 16px;
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #999;
}

/* Responsive Design */
@media (max-width: 768px) {
  .transactions-header input[type='text'] {
    width: 100%;
    margin-bottom: 10px;
  }

  .balance-info {
    margin-top: 10px;
  }

  .modal-content {
    width: 90%;
    padding: 20px;
  }
}



/* src/styles/Transactions.css */

/* ... existing styles ... */

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.refund-button,
.chargeback-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.refund-button {
  background-color: #2ecc71;
  color: #fff;
}

.refund-button:hover {
  background-color: #27ae60;
}

.chargeback-button {
  background-color: #e74c3c;
  color: #fff;
}

.chargeback-button:hover {
  background-color: #c0392b;
}

/* ... existing styles ... */