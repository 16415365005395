/* src/styles/PayoutTransactions.css */

/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');

/* General Styles */
body {
  font-family: 'Urbanist', sans-serif;
  color: #1A1A40;
}

/* Main container */
.payout-transactions-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Top row: tabs, balance, search, date filters, CSV in one straight line */
.payout-transactions-action-bar {
  display: flex;
  align-items: center;       /* vertically center everything */
  gap: 15px;                 /* space between elements */
  margin-bottom: 20px;
  flex-wrap: wrap;           /* wrap on small screens instead of overlapping */
}

/* Tab Buttons */
.tab-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #F0F2F5;
  color: #1A1A40;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-button.active,
.tab-button:hover {
  background-color: #7B61FF;
  color: #fff;
}

/* Balance Widget */
.balance-widget {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 24px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.balance-widget:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.balance-info {
  display: flex;
  flex-direction: column;
}

.balance-label {
  font-size: 14px;
  color: #1A1A40;
}

.balance-amount {
  font-size: 20px;
  font-weight: 600;
  color: #2ECC71;
}

/* Search bar */
.search-bar {
  position: relative;
}

.search-bar input {
  padding: 8px 12px 8px 35px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

/* Date filter */
.date-filter {
  display: flex;
  align-items: center;
  gap: 5px; /* small gap between "to" and date fields */
}

.date-filter input {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* CSV download button */
.download-button {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #7B61FF;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #6949FF;
}

.download-icon {
  font-size: 16px;
}

/* Table Container */
.payout-transactions-table-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.payout-transactions-table-wrapper {
  flex: 1;
  overflow-y: auto;
}

.payout-transactions-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.payout-transactions-table th,
.payout-transactions-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
}

.payout-transactions-table th {
  background-color: #F0F2F5;
  font-weight: 600;
}

.payout-transactions-table tr:hover {
  background-color: #F9F9F9;
}

.no-transactions {
  text-align: center;
  padding: 50px 0;
}

.no-transactions-message p {
  margin: 5px 0;
  color: #666;
}

/* Status Labels */
.status {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.status.success {
  background-color: #2ECC71;
}

.status.pending {
  background-color: #F1C40F;
}

.status.failed {
  background-color: #E74C3C;
}

/* Action Button */
.action-button {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #7B61FF;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #6949FF;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  gap: 5px;
}

.page-button {
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: #1A1A40;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.page-button.active {
  background-color: #B8A4FF;
  color: #fff;
}

.page-button:hover:not(.active) {
  background-color: #F0F2F5;
}

.page-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

/* Hover Effects */
.tab-button:hover,
.download-button:hover,
.action-button:hover,
.page-button:hover:not(.disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Transitions */
.tab-button,
.download-button,
.action-button,
.page-button {
  transition: all 0.3s ease;
}

/* Modal */
.custom-modal {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  margin: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  color: #333333;
}

.custom-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #333333;
}

.modal-content p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .payout-transactions-action-bar {
    flex-wrap: wrap;
    gap: 10px;
  }

  .date-filter {
    gap: 5px;
  }

  .payout-transactions-table th,
  .payout-transactions-table td {
    padding: 10px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .tab-button {
    font-size: 12px;
    padding: 8px 10px;
  }

  .balance-widget {
    padding: 10px 16px;
  }

  .balance-amount {
    font-size: 18px;
  }

  .search-bar input {
    font-size: 12px;
    padding: 6px 8px 6px 30px;
  }

  .download-button {
    font-size: 12px;
    padding: 6px 8px;
  }

  .download-icon {
    font-size: 14px;
  }

  .payout-transactions-table th,
  .payout-transactions-table td {
    padding: 8px;
    font-size: 11px;
  }

  .action-button {
    padding: 4px 8px;
    font-size: 11px;
  }

  .page-button {
    padding: 4px 6px;
    font-size: 11px;
  }
}
