/* src/styles/History.css */

/* Container */
.history-content {
  padding: 20px;
  background-color: var(--background-color);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header */
.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.history-header h2 {
  margin: 0;
  color: var(--color-primary);
}

.search-bar {
  position: relative;
}

.search-bar input {
  padding: 8px 12px 8px 35px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

/* Table Container */
.history-table-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: var(--box-shadow);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.history-table th,
.history-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
}

.history-table th {
  background-color: #F0F2F5;
  font-weight: 600;
}

.history-table tr:hover {
  background-color: #F9F9F9;
}

.no-records {
  text-align: center;
  padding: 50px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .history-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar {
    width: 100%;
    margin-top: 10px;
  }

  .history-table th,
  .history-table td {
    padding: 10px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .history-header h2 {
    font-size: 16px;
  }

  .search-bar input {
    font-size: 12px;
    padding: 6px 8px 6px 30px;
  }

  .search-icon {
    left: 8px;
  }

  .history-table th,
  .history-table td {
    padding: 8px;
    font-size: 11px;
  }
}
