/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');
@import './variables.css';

/* General Styles */
body {
  margin: 0;
  font-family: 'Urbanist', sans-serif;
  background-color: var(--background-color);
  color: var(--color-primary);
}

/* Header Section */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.welcome-message {
  flex: 1;
}

.balance-widgets {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.balance-widget {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 24px;
  border: 1px solid #e5e5e5;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.balance-widget:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.balance-info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.balance-label {
  font-size: 14px;
  color: var(--color-primary);
}

.balance-amount {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-success);
}

.date-selector {
  display: flex;
  gap: 10px;
  align-items: center;
}

.date-selector input {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Statistics Section */
.dashboard-stats {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.stat-card {
  flex: 1;
  min-width: 240px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  color: var(--color-primary);
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.stat-card h3 {
  margin-bottom: 10px;
  font-size: 20px;
}

.stat-card p {
  margin-top: 8px;
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
}

.stat-card.success {
  border-top: 4px solid var(--color-success);
}

.stat-card.pending {
  border-top: 4px solid var(--color-pending);
}

.stat-card.failed {
  border-top: 4px solid var(--color-failed);
}

/* Chart Section */
.chart-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.chart-wrapper {
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  padding: 20px;
}

/* Loading Spinner */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--color-secondary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error Message */
.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color-failed);
  font-size: 18px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .dashboard-stats {
    flex-direction: column;
  }

  .stat-card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-header .welcome-message h2 {
    font-size: 18px;
  }

  .balance-widgets {
    margin-top: 10px;
  }

  .date-selector {
    margin-top: 10px;
  }

  .stat-card h3 {
    font-size: 18px;
  }

  .stat-card p {
    font-size: 16px;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 480px) {
  .dashboard-header .welcome-message h2 {
    font-size: 16px;
  }

  .dashboard-header .date-selector input {
    font-size: 12px;
  }
}


.balance-popup {
  position: absolute;
  top: 150px; /* adjust as needed */
  left: 50px; /* adjust as needed */
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 6px 15px rgba(0,0,0,0.1);
  z-index: 1000;
  animation: fadeInScale 0.3s ease;
}

.popup-option {
  display: block;
  margin: 8px 0;
  background: var(--color-secondary);
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.withdraw-options {
  margin-top: 10px;
}

.withdraw-options p {
  font-size: 14px;
  margin-bottom: 8px;
}

.option-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@keyframes fadeInScale {
  from { opacity: 0; transform: scale(0.9);}
  to { opacity:1; transform: scale(1);}
}

.modal-overlay {
  position: fixed;
  top: 0;
  left:0;
  width:100%;
  height:100%;
  background: rgba(0,0,0,0.5);
  display:flex;
  align-items:center;
  justify-content:center;
  z-index:2000;
}

.modal-content {
  background:#fff;
  padding:20px;
  border-radius:12px;
  width:300px;
  box-shadow:0 6px 15px rgba(0,0,0,0.1);
}

.modal-content h3 {
  margin-bottom:15px;
}

.modal-content input {
  width:100%;
  padding:8px;
  margin-bottom:15px;
  border:1px solid #ccc;
  border-radius:6px;
}

.modal-buttons {
  display:flex;
  justify-content:space-between;
}

.modal-buttons button {
  flex:1;
  margin:0 5px;
  padding:10px;
  background: var(--color-secondary);
  color:#fff;
  border:none;
  border-radius:6px;
  cursor:pointer;
}
