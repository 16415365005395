/* frontend/src/styles/Settlement.css */

/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');

/* General Styles */
body {
  font-family: 'Urbanist', sans-serif;
  color: #1A1A40;
}

/* Settlement Content Wrapper */
.settlement-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header Section */
.settlement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.settlement-title {
  flex: 1;
}

.balance-widgets {
  display: flex;
  align-items: center;
  gap: 20px;
}

.balance-widget {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 24px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.balance-widget:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.balance-info {
  display: flex;
  flex-direction: column;
}

.balance-label {
  font-size: 14px;
  color: #1A1A40;
}

.balance-amount {
  font-size: 20px;
  font-weight: 600;
  color: #2ECC71;
}

.date-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-selector input {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

/* Action Bar */
.settlement-action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.settlement-tabs {
  display: flex;
  visibility: hidden;
}

/* Search and Download Actions */
.settlement-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-bar {
  position: relative;
}

.search-bar input {
  padding: 8px 12px 8px 35px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.download-button {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #7B61FF;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #6949FF;
}

.download-icon {
  font-size: 16px;
}

/* Settlement Table Container */
.settlement-table-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.settlement-table-wrapper {
  flex: 1;
  overflow-y: auto;
}

.settlement-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.settlement-table th,
.settlement-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
}

.settlement-table th {
  background-color: #F0F2F5;
  font-weight: 600;
}

.settlement-table tr:hover {
  background-color: #F9F9F9;
}

.no-settlements {
  text-align: center;
  padding: 50px 0;
}

.no-settlements-message p {
  margin: 5px 0;
  color: #666;
}

/* Status Labels */
.status {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.status.settled,
.status.success {
  background-color: #2ECC71;
}

.status.pending {
  background-color: #F1C40F;
}

.status.failed {
  background-color: #E74C3C;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  gap: 5px;
}

.page-button {
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: #1A1A40;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.page-button.active {
  background-color: #B8A4FF;
  color: #fff;
}

.page-button:hover:not(.active) {
  background-color: #F0F2F5;
}

.page-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

/* Hover Effects */
.download-button:hover,
.page-button:hover:not(.disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Transitions */
.download-button,
.page-button {
  transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .settlement-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .balance-widgets {
    margin-top: 10px;
  }

  .settlement-action-bar {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .settlement-actions {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar,
  .download-button {
    width: 100%;
  }

  .settlement-table th,
  .settlement-table td {
    padding: 10px;
    font-size: 12px;
  }

  .page-button {
    padding: 5px 8px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .settlement-header .settlement-title h2 {
    font-size: 16px;
  }

  .settlement-header .date-selector input {
    font-size: 12px;
    padding: 6px 8px;
  }

  .settlement-action-bar {
    gap: 10px;
  }

  .search-bar input {
    font-size: 12px;
    padding: 6px 8px 6px 30px;
  }

  .search-icon {
    left: 8px;
  }

  .download-button {
    font-size: 12px;
    padding: 6px 8px;
  }

  .download-icon {
    font-size: 14px;
  }

  .settlement-table th,
  .settlement-table td {
    padding: 8px;
    font-size: 11px;
  }

  .page-button {
    padding: 4px 6px;
    font-size: 11px;
  }
}
