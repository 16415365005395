/* src/styles/Users.css */

/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');

/* General Styles */
body {
  font-family: 'Urbanist', sans-serif;
  color: #1A1A40;
}

/* Users Content Wrapper */
.users-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header Section */
.users-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; /* Match spacing with other pages */
}

.users-header .users-title h2 {
  font-size: 20px; /* Match font size with other pages */
  margin: 0;
}

/* Removed date-selector styles since it's no longer used */

/* Action Bar */
.users-action-bar {
  display: flex;
  justify-content: space-between; /* Keep alignment consistent */
  align-items: center;
  margin-bottom: 20px;
}

.users-tabs {
  /* Empty placeholder for alignment */
  display: flex;
  visibility: hidden; /* Hide the empty div but keep space */
}

/* Search and Add User Actions */
.users-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-bar {
  position: relative;
}

.search-bar input {
  padding: 8px 12px 8px 35px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.add-user-button {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #7B61FF; /* Changed to match purple buttons */
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
}

.add-user-button:hover {
  background-color: #6949FF;
}

/* Users Table Container */
.users-table-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.users-table-wrapper {
  flex: 1;
  overflow-y: auto;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.users-table th,
.users-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
}

.users-table th {
  background-color: #F0F2F5;
  font-weight: 600;
}

.users-table tr:hover {
  background-color: #F9F9F9;
}

.no-users {
  text-align: center;
  padding: 50px 0;
}

.no-users-message p {
  margin: 5px 0;
  color: #666;
}

/* Reduce the width of the Create Date Time column */
.narrow-column {
  width: 150px; /* Adjust the width as needed */
}

/* Status Labels */
.status {
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
}

.status.active {
  background-color: #2ECC71; /* Green for active users */
}

.status.inactive {
  background-color: #E74C3C; /* Red for inactive users */
}

/* Action Button */
.action-button {
  padding: 6px 12px;
  border-radius: 6px;
  background-color: #7B61FF;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #6949FF;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: flex-start; /* Align to the bottom left */
  align-items: center;
  margin-top: 15px;
  gap: 5px;
}

.page-button {
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  background-color: transparent; /* No background color */
  color: #1A1A40;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.page-button.active {
  background-color: #B8A4FF; /* Light purple color for selected page */
  color: #fff;
}

.page-button:hover:not(.active) {
  background-color: #F0F2F5; /* Light gray on hover */
}

.page-button:disabled {
  background-color: #e0e0e0;
  color: #a0a0a0;
  cursor: not-allowed;
}

/* Hover Effects */
.add-user-button:hover,
.action-button:hover,
.page-button:hover:not(.disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Transitions */
.add-user-button,
.action-button,
.page-button {
  transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .users-header {
    flex-direction: column;
    align-items: flex-start;
  }

  /* Removed date-selector styles since it's no longer used */

  .users-action-bar {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .users-actions {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar,
  .add-user-button {
    width: 100%;
  }

  .users-table th,
  .users-table td {
    padding: 10px;
    font-size: 12px;
  }

  .action-button {
    padding: 4px 8px;
    font-size: 11px;
  }

  .page-button {
    padding: 5px 8px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .users-header .users-title h2 {
    font-size: 16px;
  }

  /* Removed date-selector styles since it's no longer used */

  .users-action-bar {
    gap: 10px;
  }

  .search-bar input {
    font-size: 12px;
    padding: 6px 8px 6px 30px;
  }

  .search-icon {
    left: 8px;
  }

  .add-user-button {
    font-size: 12px;
    padding: 6px 8px;
  }

  .users-table th,
  .users-table td {
    padding: 8px;
    font-size: 11px;
  }

  .action-button {
    padding: 4px 8px;
    font-size: 11px;
  }

  .page-button {
    padding: 4px 6px;
    font-size: 11px;
  }
}
