/* LoginPage.module.css */

/* Full-page container for the login form */
.loginWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%; /* Ensure full-width */
  box-sizing: border-box;
}

/* Modern login form container */
.loginFormContainer {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 400px;
  z-index: 2;
  text-align: center;
  font-family: 'Urbanist', sans-serif;
  opacity: 0;
  animation: slideIn 1s forwards ease-out;
  box-sizing: border-box;
}

/* Slide-in animation for the form */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Logo styling */
.loginLogo img {
  max-width: 150px;
  margin-bottom: 30px;
}

/* Input field group styling */
.inputGroup {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

/* Password group to position the show/hide button */
.passwordGroup {
  position: relative;
}

/* Input field styling */
.inputField {
  width: 100%;
  padding: 14px;
  padding-right: 60px; /* Ensure space for the show/hide button */
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f2f3f7;
  color: #555;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
  transition: border 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

/* Focus state for input fields */
.inputField:focus {
  border: 1px solid #6A85B6;
  outline: none;
  box-shadow: 0 0 8px rgba(106, 133, 182, 0.3);
}

/* Show/Hide Password Button */
.showPasswordButton {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%); /* Aligns the button vertically within the input */
  background: none;
  border: none;
  color: #555;
  font-size: 14px;
  cursor: pointer;
  font-family: 'Urbanist', sans-serif;
  opacity: 0.5;
  padding: 0;
}

/* Show/Hide Password Button Hover Effect */
.showPasswordButton:hover {
  opacity: 0.8;
}

/* Login Button with hover effects */
.loginButton {
  width: 100%;
  padding: 14px;
  background-color: #7B61FF;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 6px 18px rgba(123, 97, 255, 0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  margin-top: 10px;
}

/* Login Button Hover Effects */
.loginButton:hover {
  background-color: #584BC1;
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(123, 97, 255, 0.3);
}

/* Disabled Login Button Styling */
.loginButton:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
  box-shadow: none;
}

/* Error message styling */
.errorMessage {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
  font-family: 'Urbanist', sans-serif;
  text-align: center;
  padding-left: 5px;
}

/* Forgot password link styling */
.forgotPassword {
  color: #7B61FF;
  text-decoration: none;
  font-size: 12px;
  display: block;
  margin-top: 15px;
  font-family: 'Urbanist', sans-serif;
  text-align: center; /* Center the "Forgot Password?" link */
}

.forgotPassword:hover {
  color: #584BC1;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .loginFormContainer {
    padding: 30px 20px;
  }

  .loginLogo img {
    max-width: 120px;
    margin-bottom: 20px;
  }

  .inputField {
    padding: 12px;
    font-size: 13px;
  }

  .showPasswordButton {
    font-size: 12px;
    right: 10px;
  }

  .loginButton {
    padding: 12px;
    font-size: 15px;
  }

  .forgotPassword {
    font-size: 11px;
  }
}