/* src/styles/AdditionalFunctionality.css */

/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');

/* General Styles */
body {
  font-family: 'Urbanist', sans-serif;
  color: #1A1A40;
}

/* Additional Functionality Content Wrapper */
.additional-functionality-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header Section */
.additional-functionality-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; /* Match spacing with other pages */
}

.additional-functionality-header .additional-functionality-title h2 {
  font-size: 20px; /* Match font size with other pages */
  margin: 0;
}

/* Message Container */
.additional-functionality-message-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-card {
  text-align: center;
  max-width: 600px;
}

.message-card h3 {
  font-size: 24px;
  color: #7B61FF;
  margin-bottom: 20px;
}

.message-card p {
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
}

.message-card a {
  color: #7B61FF;
  text-decoration: none;
}

.message-card a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .additional-functionality-header .additional-functionality-title h2 {
    font-size: 18px;
  }

  .message-card h3 {
    font-size: 22px;
  }

  .message-card p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .additional-functionality-header .additional-functionality-title h2 {
    font-size: 16px;
  }

  .message-card h3 {
    font-size: 20px;
  }

  .message-card p {
    font-size: 13px;
  }
}
