/* src/styles/MainContent.css */

/* Main Content Container */
.main-content {
    position: fixed;
    top: 20px;
    left: calc(20px + 220px + 20px); /* Left margin + Sidebar width + spacing */
    right: 20px;
    height: calc(100vh - 40px); /* Full height minus top and bottom margins */
    background-color: #ffffff; /* Same as Sidebar */
    color: #1A1A40;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 16px; /* Same rounded edges as Sidebar */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05); /* Same shadow as Sidebar */
    z-index: 1; /* Ensure it's beneath the Sidebar */
    overflow: hidden; /* Prevent content from overflowing */
    display: flex;
    flex-direction: column;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .main-content {
      top: 15px;
      left: calc(15px + 180px + 15px); /* Adjusted for Sidebar width and margins */
      right: 15px;
      height: calc(100vh - 30px);
      padding: 15px;
    }
  }
  
  @media (max-width: 768px) {
    .main-content {
      top: 10px;
      left: calc(10px + 160px + 10px);
      right: 10px;
      height: calc(100vh - 20px);
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .main-content {
      top: 5px;
      left: calc(5px + 140px + 5px);
      right: 5px;
      height: calc(100vh - 10px);
      padding: 5px;
    }
  }
  