/* ForgotPassword.module.css */

/* Wrapper to center the forgot password container */
.forgotPasswordWrapper {
  position: absolute; /* Position it absolutely within the fixed Background */
  top: 50%; /* Position from the top */
  left: 50%; /* Position from the left */
  transform: translate(-50%, -50%); /* Offset to truly center */
  padding: 20px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  z-index: 1; /* Ensure it sits above the background */
}

/* Forgot password form container */
.forgotPasswordContainer {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  width: 100%;
  text-align: center;
  font-family: 'Urbanist', sans-serif; /* Ensure consistency with LoginPage */
  opacity: 0;
  animation: fadeIn 1s forwards ease-out;
  box-sizing: border-box;
}

/* Fade-in animation for the form */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Heading styling */
.heading {
  color: #1A1A40;
  font-size: 20px;
  margin-bottom: 20px;
}

/* Paragraph styling */
.paragraph {
  color: #555;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center; /* Align text to the left for readability */
  line-height: 1.5; /* Improve readability */
}

/* Link styling */
.link {
  color: #7B61FF;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
}

.link:hover {
  color: #584BC1;
}

/* Back to Login button styling */
.backToLoginButton {
  display: inline-block;
  margin-top: 30px;
  background-color: #7B61FF;
  color: white; /* Font color is set to white */
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 6px 18px rgba(123, 97, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-decoration: none;
  font-family: 'Urbanist', sans-serif;
  font-size: 14px;
}

.backToLoginButton:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 20px rgba(123, 97, 255, 0.3);
  color: white; /* Ensures font color remains white on hover */
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .forgotPasswordContainer {
    padding: 30px 20px;
  }

  .heading {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .paragraph {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .backToLoginButton {
    padding: 8px 16px;
    font-size: 13px;
  }
}
