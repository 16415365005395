/* Background.module.css */

:root {
  --bg-gradient-start: #1A1A40;
  --bg-gradient-end: #2C2C72;
  --bg-gradient-animation-start: #1A1A40;
  --bg-gradient-animation-end: #2C2C72;
  --shape-color: rgba(255, 255, 255, 0.1);
  --animation-duration: 12s;
  --animation-delay: 0s;
}

.backgroundWrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(135deg, var(--bg-gradient-start), var(--bg-gradient-end));
  background-size: 400% 400%;
  animation: gradientShift 15s ease infinite;
  position: fixed; /* Fixed to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensures the background stays behind other content */
  overflow: hidden;
  transition: background 0.5s ease;
}

/* Animated Gradient */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Shape Base Styles */
.shape {
  position: absolute;
  border-radius: 50%;
  opacity: 0.6;
  background: var(--shape-color);
  animation: float var(--animation-duration) ease-in-out infinite;
  filter: blur(30px);
  will-change: transform, opacity;
}

/* Specific Shape Styles */
.shapeBefore {
  width: 200px;
  height: 200px;
  top: -80px;
  left: -80px;
  animation-duration: 18s;
  animation-delay: 0s;
}

.shapeAfter {
  width: 350px;
  height: 350px;
  bottom: -150px;
  right: -150px;
  animation-duration: 22s;
  animation-delay: -2s;
}

.shapeThird {
  width: 150px;
  height: 150px;
  top: 25%;
  left: 15%;
  animation-duration: 20s;
  animation-delay: -4s;
  background: rgba(255, 255, 255, 0.05);
}

.shapeFourth {
  width: 250px;
  height: 250px;
  bottom: 25%;
  right: 10%;
  animation-duration: 16s;
  animation-delay: -1s;
  background: rgba(255, 255, 255, 0.07);
}

/* Additional Shapes for Variety */
.shapeFifth {
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-radius: 10%;
  animation-duration: 14s;
  animation-delay: -3s;
  background: rgba(255, 255, 255, 0.08);
}

.shapeSixth {
  width: 180px;
  height: 180px;
  bottom: 10%;
  left: -90px;
  animation-duration: 19s;
  animation-delay: -5s;
  background: rgba(255, 255, 255, 0.06);
}

/* Animation Keyframes */
@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0.6;
  }
  50% {
    transform: translate(20px, -20px) rotate(180deg);
    opacity: 0.8;
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
    opacity: 0.6;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .shapeFifth,
  .shapeSixth {
    display: none; /* Hide extra shapes on medium screens */
  }
}

@media (max-width: 768px) {
  .shapeBefore {
    width: 150px;
    height: 150px;
    top: -60px;
    left: -60px;
  }

  .shapeAfter {
    width: 250px;
    height: 250px;
    bottom: -100px;
    right: -100px;
  }

  .shapeThird,
  .shapeFourth {
    display: none; /* Hide additional shapes on smaller screens */
  }
}

/* Reduced Motion for Accessibility */
@media (prefers-reduced-motion: reduce) {
  .backgroundWrapper {
    animation: none;
  }

  .shape {
    animation: none;
    filter: blur(10px);
  }
}

/* Interactive Effects */
.backgroundWrapper:hover .shape {
  animation-play-state: paused;
}
