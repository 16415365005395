/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');

/* Sidebar Container */
.sidebar {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 220px;
  height: calc(100vh - 40px);
  background-color: #ffffff;
  color: #1A1A40;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  z-index: 2;
  overflow-y: auto;
  transition: width 0.3s ease;
}

/* Sidebar Logo */
.sidebar .logo {
  text-align: center;
  margin-bottom: 25px;
}

.sidebar .logo img {
  max-width: 100%;
  height: 35px;
  object-fit: contain;
  border-radius: 8px;
}

/* Navigation Menu */
.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  margin-bottom: 10px;
}

/* Base Navigation Item */
.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1A1A40;
  text-decoration: none;
  font-family: 'Urbanist', sans-serif;
  font-size: 15px;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
  background: none;
  border: none;
}

/* Hover and Active States for Main Navigation Items */
.nav-item:hover,
.nav-item.active {
  background-color: #7B61FF;
  color: #ffffff;
}

/* Expanded Tab Styling */
.nav-item.expandable[aria-expanded='true'] {
  background-color: rgba(123, 97, 255, 0.1); /* Light purple background */
  color: #7B61FF; /* Purple text color */
  margin-bottom: 6px; /* Adjusted margin-bottom to control gap */
}

/* Chevron Styling */
.chevron {
  font-size: 12px;
  margin-left: 10px;
  color: rgba(26, 26, 64, 0.5); /* Subtle color */
}

/* Submenu Styling */
.submenu {
  list-style: none;
  padding-left: 20px; /* Indentation for submenu items */
  margin-top: 0;
  overflow: hidden; /* Hide content when collapsed */
  height: 0; /* Start with height 0 for collapse */
  transition: height 0.3s ease-out; /* Smooth animation */
}

/* Submenu List Items */
.submenu li {
  margin-bottom: 8px;
}

/* Submenu Links */
.submenu-item {
  display: block;
  color: #1A1A40;
  text-decoration: none;
  font-size: 14px;
  font-family: 'Urbanist', sans-serif;
  padding: 10px 14px;
  border-radius: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover and Active States for Submenu Items */
.submenu-item:hover {
  background-color: #7B61FF; /* Same purple color as main tabs on hover */
  color: #ffffff;
}

.submenu-item.active {
  background-color: #7B61FF; /* Same purple as active main tabs */
  color: #ffffff;
}

/* Logout Button */
.logout-button {
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 8px;
  background: none; /* No background color */
  color: #1A1A40; /* Same color as nav items */
  font-size: 15px;
  cursor: pointer;
  text-align: left;
  font-family: 'Urbanist', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Hover and Active States for Logout Button */
.logout-button:hover,
.logout-button.active {
  background-color: #7B61FF; /* Same hover effect as other nav items */
  color: #ffffff;
}

/* Responsive Design */
/* Adjust styles for different screen sizes */

@media (max-width: 1024px) {
  .sidebar {
    width: 180px;
    left: 15px;
    top: 15px;
    height: calc(100vh - 30px);
  }

  .sidebar .logo img {
    height: 30px;
  }

  .nav-item {
    font-size: 14px;
    padding: 10px 12px;
  }

  .submenu-item {
    font-size: 13px;
    padding: 8px 10px;
  }

  .chevron {
    font-size: 10px;
  }

  .nav-item.expandable[aria-expanded='true'] {
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 160px;
    left: 10px;
    top: 10px;
    height: calc(100vh - 20px);
  }

  .sidebar .logo img {
    height: 25px;
  }

  .nav-item {
    font-size: 13px;
    padding: 8px 10px;
  }

  .submenu-item {
    font-size: 12px;
    padding: 6px 8px;
  }

  .chevron {
    font-size: 8px;
  }

  .nav-item.expandable[aria-expanded='true'] {
    margin-bottom: 4px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 140px;
    left: 5px;
    top: 5px;
    height: calc(100vh - 10px);
  }

  .sidebar .logo img {
    height: 20px;
  }

  .nav-item {
    font-size: 12px;
    padding: 6px 8px;
  }

  .submenu-item {
    font-size: 11px;
    padding: 5px 7px;
  }

  .chevron {
    font-size: 6px;
  }

  .nav-item.expandable[aria-expanded='true'] {
    margin-bottom: 3px;
  }
}
