/* Import Urbanist Font */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600&display=swap');

/* General Styles */
body {
  font-family: 'Urbanist', sans-serif;
  color: #1A1A40;
}

/* Profile Content Wrapper */
.profile-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Header Section */
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.profile-header .profile-title h2 {
  font-size: 24px;
  margin: 0;
}

/* Profile Information Container */
.profile-info-container {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  overflow-y: auto;
}

/* Section Headings */
.section-heading {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #7B61FF;
  border-bottom: 2px solid #F0F2F5;
  padding-bottom: 5px;
}

/* Profile Form */
.profile-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Form Group */
.form-group {
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
}

.form-group label {
  font-weight: 600;
  margin-bottom: 5px;
  color: #333;
}

.form-group input {
  padding: 10px 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 14px;
  background-color: #f9f9f9;
  color: #666;
}

.form-group input[readonly] {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

/* Password group styling */
.password-group,
.callback-url-group,
.bank-details-group,
.wallet-address-group {
  position: relative;
}

.password-field,
.callback-url-field,
.bank-details-field,
.wallet-address-field {
  display: flex;
  align-items: center;
  position: relative;
}

.password-field input,
.callback-url-field input,
.bank-details-field input,
.wallet-address-field input {
  width: 100%;
  padding-right: 30px;
}

.edit-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #666;
}

.edit-icon:hover {
  color: #000;
}

/* Modal styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  position: relative;
  width: 400px;
  max-width: 90%;
}

.modal-content h3 {
  margin-top: 0;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #666;
  font-size: 1.2em;
}

.close-icon:hover {
  color: #000;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #7B61FF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #6A51E5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-group {
    width: 100%;
  }

  .profile-header .profile-title h2 {
    font-size: 20px;
  }

  .section-heading {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .profile-info-container {
    padding: 20px;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input {
    font-size: 13px;
    padding: 8px 10px;
  }

  .section-heading {
    font-size: 14px;
  }
}
