/* src/styles/variables.css */

:root {
    --color-primary: #1A1A40;
    --color-secondary: #7B61FF;
    --color-success: #2ECC71;
    --color-pending: #F1C40F;
    --color-failed: #E74C3C;
    --background-color: #f0f2f5;
    --border-radius: 16px;
    --box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    --welcome-font-size: 20px;
    --transition-speed: 0.3s;
  }
  